
import styled from 'styled-components'

import COLORS from 'constants/colors'
import LAYOUT from 'constants/layout'
import MEDIA from 'constants/media'


import PaperTearBgr from 'assets/images/backgrounds/bgr-paper-tear-short.webp'

import { Props } from './types'

export const Regular = styled.div`
  margin: ${(props: Props): string =>
    props.margined ? `${LAYOUT.VERTICALSPACEMOBILE} auto` : '0 auto'};
  padding: ${(props: Props): string =>
    props.padded ? `${LAYOUT.VERTICALSPACEMOBILE} 20px` : '0 20px'};
  text-align: ${(props: Props): string => (props.center ? 'center' : 'left')};
  ${({ short }: Props): string => (short ? 'max-width:800px;' : '')}
  ${({ tiny }: Props): string => (tiny ? 'max-width:640px;' : '')}
  ${({ large }: Props): string => (large ? 'max-width:1280px;' : '')}

  ${MEDIA.medium`
    margin: ${(props: Props): string =>
    props.margined ? `${LAYOUT.VERTICALSPACE} auto` : '0 auto'};
    padding: ${(props: Props): string =>
    props.padded ? `${LAYOUT.VERTICALSPACE} 20px` : '0 20px'};
    ${({ minheight }: Props): string => (minheight ? 'min-height:360px;' : '')}
  `}
`

export const PaddedBelow = styled(Regular)`
  padding-bottom: ${LAYOUT.VERTICALSPACEMOBILE};
  ${MEDIA.medium`
    padding-bottom: ${LAYOUT.VERTICALSPACE};
  `}
`

export const Flex = styled(Regular)`
  display: flex;
  justify-content: center;
  justify-content: ${(props: Props): string => (props.alignLeft ? 'flex-start' : 'center')};
  align-items: 'start;
  align-items: ${(props: Props): string => (props.alignTop ? 'align-items' : 'flex-start')};
  flex-wrap: wrap;
  width: 100%;
`

export const FlexFeatureItem = styled.div`
  background: ${COLORS.LIGHTYELLOW};
  margin: ${LAYOUT.PADDING};
  padding: ${LAYOUT.PADDING};
  align-self: stretch;
  width: 100%;
  ${MEDIA.medium`
    flex: 1;
  `}
`

export const FlexColumn = styled.div`
  padding-left: ${LAYOUT.PADDING};
  padding-right: ${LAYOUT.PADDING};
  text-align: ${(props: Props): string => (props.center ? 'center' : 'left')};
  width: 100%;
  ${MEDIA.medium`
    flex: 1;
  `}
`

export const ImageWrapper = styled(Regular)`
  margin: 0 auto 60px;
  max-width: 960px;
`

export const Paper = styled.div`
  background: rgba(230, 230, 231, 0.5);
  padding: ${LAYOUT.VERTICALSPACEMOBILE} 20px 5px 20px;
  position: relative;
  margin: 40px 0 60px;
  &:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 0;
    width: 100%;
    height: 1px;
    box-shadow: 0px -5px 4px 0px rgba(0, 0, 0, 0.47);
  }
  &:after {
    content: '';
    background-image: url(${PaperTearBgr});
    height: 100px;
    width: 100%;
    background-position: center center;
    background-size: cover;
    position: absolute;
    bottom: -100px;
    left: 0;
    opacity: 0.5;
  }
  ${MEDIA.medium`
    padding: ${LAYOUT.VERTICALSPACE} 20px 20px 20px;
    margin: 60px 0 80px;
  `}
`

export const Gray = styled(Regular)`
  padding: ${LAYOUT.VERTICALSPACEMOBILE} 20px;
  background: rgba(230, 230, 231, 0.7);
  ${MEDIA.medium`
    padding: ${LAYOUT.VERTICALSPACE} 20px;
  `}
`

export const Blue = styled(Regular)`
  padding: ${LAYOUT.VERTICALSPACEMOBILE} 20px;
  background: ${COLORS.BGRBLUE};
  ${MEDIA.medium`
    padding: ${LAYOUT.VERTICALSPACE} 20px;
  `}
`

export const Yellow = styled(Regular)`
  padding: ${LAYOUT.VERTICALSPACEMOBILE} 20px;
  background: ${COLORS.LIGHTYELLOW};
  ${MEDIA.medium`
  padding: ${LAYOUT.VERTICALSPACE} 20px;
`}
`

export const SubpageTop = styled.div`
  margin: 40px auto 40px;
  position: relative;
  z-index: 2;
  padding: 0 20px;
  max-width: 800px;
  min-height: 255px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const BelowSection = styled(Regular)`
  max-width: 700px;
`
