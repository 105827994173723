import React from 'react'

import * as themes from './styles'
import { Props } from './types'

const Title: React.FC<Props> = ({
  level = 1,
  ...props
}): React.ReactElement => {
  const theme = (typeof level === 'number') && isFinite(level) ? `h${level}` : level
  const Component = themes[theme]

  return <Component {...props} />
}

export * from './types'

export default Title
