export enum Theme {
  regular = 'regular',
  flex = 'flex',
  flexColumn = 'flexColumn',
  flexFeatureItem = 'flexFeatureItem',
  paddedBelow = 'paddedBelow',
  imageWrapper = 'imageWrapper',
  paper = 'paper',
  gray = 'gray',
  blue = 'blue',
  yellow = 'yellow',
  subpageTop = 'subpageTop',
  belowSection = 'belowSection',
}

export interface Props {
  id?: string,
  alignLeft?: boolean,
  alignTop?: boolean,
  center?: boolean,
  large? : boolean,
  margined?: boolean,
  minheight?: boolean,
  padded?: boolean,
  short?: boolean,
  tiny?: boolean,
  theme?: Theme,
  width720?: boolean,
  className?: string,
  left?: boolean,
}
