
import styled, { css } from 'styled-components'

import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'
import MEDIA from 'constants/media'

import { Props } from './types'

export const page = styled.h1`
  color: ${COLORS.BLUE};
  font-size: ${FONTS.TITLEONEMOBILE};
  font-weight: ${FONTS.WEIGHTDEMI};
  margin: 0 auto;
  line-height: 1.1;
  ${MEDIA.medium`
    font-size: ${FONTS.TITLEONE};
  `}
`

export const pageLarge = styled.h1`
  color: ${COLORS.BLUE};
  font-size: ${FONTS.TITLEONEMOBILE};
  font-weight: ${FONTS.WEIGHTDEMI};
  margin: 0 auto;
  line-height: 1.1;
  ${MEDIA.medium`
    font-size: ${FONTS.FONTTITLELARGE};
  `}
`

const h1Styles = css`
  font-size: ${FONTS.TITLEONE};
  font-family: ${FONTS.FAMILYSECONDARY};
  font-weight: ${FONTS.WEIGHTDEMI};
  margin-top: 0;
  margin-bottom: ${(props: Props): string => (props.margin ? '30px' : '0')};
  max-width: ${(props: Props): string => (props.short ? '600px' : '1040px')};
`

const h2Styles = css`
  font-size: ${FONTS.TITLETWOMOBILE};
  font-family: ${FONTS.FAMILYSECONDARY};
  font-weight: ${FONTS.WEIGHTDEMI};
  margin-top: 0;
  margin-bottom: 20px;
  text-align: ${(props: Props): string => (props.alignLeft ? 'left' : 'center')};
  ${MEDIA.medium`
    font-size: ${FONTS.TITLETWO};
  `}
`

const h3Styles = css`
  font-size: ${FONTS.TITLETHREEMOBILE};
  font-family: ${FONTS.FAMILYSECONDARY};
  font-weight: ${FONTS.WEIGHTDEMI};
  margin: ${(props: Props): string => (props.noMargin ? '30px auto 0 auto' : '30px auto')};
  padding: ${(props: Props): string => (props.padded ? '0 20px' : '0 0')};
  max-width: ${(props: Props): string => (props.fullWidth ? 'none' : '630px')};
  ${MEDIA.medium`
    font-size: ${FONTS.TITLETHREE};
  `}
`

const h4Styles = css`
  font-size: ${FONTS.PARAGRAPH};
  font-family: ${FONTS.TITLEFOURMOBILE};
  font-weight: ${FONTS.WEIGHTDEMI};
  margin: ${(props: Props): string => (props.noMargin ? '30px auto 0 auto' : '30px auto')};
  ${MEDIA.medium`
    font-size: ${FONTS.TITLEFOUR};
  `}
`

export const h1 = styled.h1`${h1Styles}`

export const h2 = styled.h2`${h2Styles}`

export const h3 = styled.h3`${h3Styles}`

export const h4 = styled.h4`${h4Styles}`

export const divThatLooksLikeH1 = styled.div`${h1Styles}`

export const h2ThatLooksLikeH3 = styled.h2`${h3Styles}`
