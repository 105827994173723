export enum Color {
  black = 'black',
  regular = 'regular',
}

export enum Size {
  large = 'large',
  regular = 'regular',
  small = 'small',
}

export interface Props {
  buttons?: React.ReactElement,
  color?: Color,
  size?: Size,
  speech?: string,
  title?: Node | string,
}
