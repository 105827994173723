export enum Theme {
  centered = 'centered',
  ctaBold = 'ctaBold',
  ctaBoldBlue = 'ctaBoldBlue',
  giant = 'giant',
  intro = 'intro',
  introLarge = 'introLarge',
  large = 'large',
  medium = 'medium',
  regular = 'regular',
  small = 'small',
  terms = 'terms',
  tiny = 'tiny',
}

export interface Props {
  alignCenter?: boolean,
  alignLeft?: boolean,
  fullWidth?: boolean,
  noMargin?: boolean,
  padded?: boolean,
  short?: boolean,
  strong?: boolean,
  theme?: Theme,
}
