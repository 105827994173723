
import styled from 'styled-components'

import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'
import LAYOUT from 'constants/layout'
import MEDIA from 'constants/media'


import { Props } from './types'

export const Regular = styled.p`
  font-size: ${FONTS.PARAGRAPHMOBILE};
  font-weight: ${FONTS.WEIGHTLIGHT};
  font-family: ${FONTS.FAMILYPRIMARY};
  max-width: ${(props: Props): string => (props.short ? `${LAYOUT.WIDTHVERYSHORT}` : '')};
  margin: ${(props: Props): string => (props.short ? '0 auto 20px' : '0 0 20px 0')};
  ${MEDIA.medium`
    font-size: ${FONTS.PARAGRAPH};
  `}
  &:last-child {
    margin-bottom: 0;
  }
`

export const Tiny = styled.p`
  font-family: ${FONTS.FAMILYPRIMARY};
  font-size: ${FONTS.PARAGRAPHTINY};
  font-weight: ${FONTS.WEIGHTLIGHT};
  margin: 0 0 20px 0;
`

export const Small = styled.p`
  font-family: ${FONTS.FAMILYPRIMARY};
  font-size: ${FONTS.PARAGRAPHSMALL};
  font-weight: ${FONTS.WEIGHTLIGHT};
  text-align: ${(props: Props): string => (props.alignCenter ? 'center' : 'inherit')};
  margin: 0 0 20px 0;
`

export const Medium = styled.p`
  font-family: ${FONTS.FAMILYPRIMARY};
  font-size: ${FONTS.PARAGRAPHSMALL};
  font-weight: ${FONTS.WEIGHTLIGHT};
  margin: 0 0 20px 0;
  ${MEDIA.medium`
    font-size: ${FONTS.PARAGRAPHMEDIUM};
`}
`

export const Large = styled.p`
  font-family: ${FONTS.FAMILYPRIMARY};
  font-size: ${FONTS.PARAGRAPHMEDIUM};
  font-weight: ${FONTS.WEIGHTLIGHT};
  margin: 0 auto 20px auto;
  max-width: ${(props: Props): string => (props.short ? `${LAYOUT.WIDTHVERYSHORT}` : 'none')};
  &:last-child {
    margin-bottom: 0;
  }
  ${MEDIA.medium`
    font-size: ${FONTS.PARAGRAPHLARGE};
  `}
`

export const Giant = styled.p`
  font-family: ${FONTS.FAMILYPRIMARY};
  font-size: ${FONTS.PARAGRAPHGIANTMOBILE};
  font-weight: ${FONTS.WEIGHTLIGHT};
  ${MEDIA.medium`
    font-size: ${FONTS.PARAGRAPHGIANT};
  `}
`

export const Intro = styled.p`
  font-family: ${FONTS.FAMILYPRIMARY};
  font-size: ${FONTS.PARAGRAPHMEDIUM};
  font-weight: ${(props: Props): string =>
    props.strong ? `${FONTS.WEIGHTDEMI}` : `${FONTS.WEIGHTLIGHT}`};
  font-style: ${(props: Props): string => (props.strong ? 'italic' : 'normal')};
  margin-top: 0;
  margin-bottom: ${(props: Props): string => (props.noMargin ? '0' : '20px')};
  margin-left: ${(props: Props): string => (props.short ? 'auto' : '0')};
  margin-right: ${(props: Props): string => (props.short ? 'auto' : '0')};
  padding: ${(props: Props): string => (props.padded ? '0 20px' : '0 0')};
  max-width: ${(props: Props): string => (props.short ? '600px' : '1040px')};
  text-align: ${(props: Props): string => (props.alignLeft ? 'left' : 'inherit')};
  ${MEDIA.medium`
    font-size: ${FONTS.PARAGRAPH};
    margin-bottom: ${(props: Props): string => (props.noMargin ? '0' : '40px')};
  `}
`

export const IntroLarge = styled.p`
  font-family: ${FONTS.FAMILYPRIMARY};
  font-size: ${FONTS.PARAGRAPHLARGEINTROMOBILE};
  font-weight: ${FONTS.WEIGHTLIGHT};
  margin: 0 auto 20px;
  max-width: ${(props: Props): string => (props.fullWidth ? 'none' : '800px')};
  text-align: ${(props: Props): string => (props.alignLeft ? 'left' : 'center')};
  ${MEDIA.medium`
    font-size: ${FONTS.PARAGRAPHLARGEINTRO};
  `}
`

export const CtaBold = styled.p`
  font-family: ${FONTS.FAMILYPRIMARY};
  font-size: ${FONTS.FONTLARGE};
  font-weight: ${FONTS.WEIGHTBOLD};
  margin: 0 auto 20px;
  max-width: ${(props: Props): string => (props.fullWidth ? 'none' : '640px')};
  text-align: ${(props: Props): string => (props.alignLeft ? 'left' : 'center')};
  ${MEDIA.medium`
    font-size: ${FONTS.FONTXLARGE};
  `}
`

export const CtaBoldBlue = styled.p`
  color: ${COLORS.BLUE};
  font-family: ${FONTS.FAMILYPRIMARY};
  font-size: ${FONTS.FONTXLARGE};
  font-weight: ${FONTS.WEIGHTBOLD};
  margin: 0 auto 20px;
  max-width: ${(props: Props): string => (props.fullWidth ? 'none' : '640px')};
  text-align: ${(props: Props): string => (props.alignLeft ? 'left' : 'center')};
  ${MEDIA.medium`
    font-size: ${FONTS.FONTXXLARGE};
  `}
`

export const Terms = styled.p`
  font-family: ${FONTS.FAMILYPRIMARY};
  font-size: ${FONTS.PARAGRAPHTINY};
  ${MEDIA.medium`
    font-size: ${FONTS.PARAGRAPHTINY};
  `}
`

export const Centered = styled(Regular)`
  text-align: center;
`
