export enum Level {
  page = 'page',
  pageLarge = 'pageLarge',
  divThatLooksLikeH1 = 'divThatLooksLikeH1',
  h2ThatLooksLikeH3 = 'h2ThatLooksLikeH3',
}

export interface Props {
  level?: number | Level,
  page?: boolean,
  center?: boolean,
  margin?: boolean,
  short?: boolean,
  alignLeft?: boolean,
  padded?: boolean,
  fullWidth?: boolean,
  noMargin?: boolean,
}
