import React, {
  Children,
  cloneElement,
} from 'react'
import loadable from '@loadable/component'

import { VideoProp } from './themes/Video/types'

import {
  Props,
  Theme,
} from './types'

const PageHeroImage = loadable(() => import('./themes/Image').catch(() => () => null))
const PageHeroTitle = loadable(() => import('./Title').catch(() => () => null))
const PageHeroVideo = loadable(() => import('./themes/Video').catch(() => () => null))

const PageHero: React.FC<Props> = ({
  children,
  imgMobile = undefined,
  video = undefined,
  videoPoster = undefined,
  theme = Theme.about,
  ...props
}): React.ReactElement => {
  const newChildren = Children.map(children, child => {
    let newChild = child

    if (
      Children.count(children) === 1 &&
      child &&
      typeof child === 'string'
    ) {
      newChild = <PageHeroTitle>{child}</PageHeroTitle>
    }

    if (!React.isValidElement(newChild)) {
      return child
    }

    return cloneElement(newChild, {
      parentTheme: theme,
      ...(child && React.isValidElement(child) && child.props),
    })
  })

  if (theme === Theme.video && PageHeroVideo) {
    return (
      <PageHeroVideo
        imgMobile={imgMobile}
        video={video as VideoProp}
        videoPoster={videoPoster}
      >
        {newChildren}
      </PageHeroVideo>
    )
  }

  return (
    <PageHeroImage theme={theme} {...props}>
      {newChildren}
    </PageHeroImage>
  )
}

export * from './types'

export default PageHero
