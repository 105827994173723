
import styled from 'styled-components'

import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'
import LAYOUT from 'constants/layout'
import MEDIA from 'constants/media'


import {
  Color,
  Props,
  Size,
} from './types'

export const AskQuestionWrap = styled.div`
  text-align: center;
  margin: ${LAYOUT.VERTICALSPACEMOBILE} 0;
  ${MEDIA.medium`
    margin: ${LAYOUT.VERTICALSPACE} 0;
  `}
`

export const AskQuestionContent = styled.div`
  padding: 0 20px;
  max-width: ${LAYOUT.WIDTHSHORT};
  margin: auto;
`

export const QuestionText = styled.div<Props>`
  font-size: ${FONTS.PARAGRAPHMOBILE};
  font-weight: ${FONTS.WEIGHTLIGHT};
  margin: 20px auto 40px;
  max-width: 500px;
  ${MEDIA.medium`
    font-size: ${(props: Props): string =>
    props.size === Size.large ? `${FONTS.PARAGRAPHLARGE}` : `${FONTS.PARAGRAPH}`};
  `}
`

export const QuestionTitle = styled.h2`
  color: ${(props: Props): string => (props.color === Color.black ? `${COLORS.BLACK}` : `${COLORS.BLUE}`)};
  font-size: ${(props: Props): string => {
    if (props.size === Size.large) {
      return `${FONTS.TITLETWOMOBILE}`
    }
    else if (props.size === Size.small) {
      return `${FONTS.TITLETHREEMOBILE}`
    }

    return `${FONTS.TITLE_ASK_QUESTION}`
  }};
  font-weight: ${FONTS.WEIGHTDEMI};
  margin-top: 0;
  margin-bottom: 20px;
  ${MEDIA.medium`
    font-size: ${(props: Props): string => {
    if (props.size === Size.large) {
      return `${FONTS.TITLETWO}`
    }
    else if (props.size === Size.small) {
      return `${FONTS.TITLETHREE}`
    }

    return `${FONTS.TITLE_ASK_QUESTION}`
  }};
  `}
`
