import React from 'react'

import * as themes from './styles'
import {
  Props,
  Theme,
} from './types'

const Paragraph: React.FC<Props> = ({
  theme = Theme.regular,
  ...props
}): React.ReactElement => {
  const Component = themes[theme.charAt(0).toUpperCase() + theme.slice(1)]

  return <Component {...props} />
}

export * from './types'

export default Paragraph
